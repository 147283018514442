#shepherdModalMaskOpening {
  background-color: red !important;
}
.shepherd-element {
  border-radius: 15px !important;
  overflow: hidden;
  padding: 7px 10px;
  /* max-width: 92vw !important; */
}

.shepherd-content {
}

.shepherd-header {
  background-color: #fff !important;
}

.shepherd-title {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

.shepherd-text {
}

.shepherd-footer {
  margin-top: 10px;
}

.shepherd-button {
  background-color: #fff !important;
  border-radius: 15px !important;
}
.shepherd-button-primary {
  background-color: #2f7eb2 !important;
  color: #fff !important;
}
.shepherd-button-primary:hover {
  background-color: #3311db !important;
}
