@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
html,
body,
#root {
  font-family: "DM Sans", sans-serif;
  width: 100% !important;
  height: 100% !important;
}

#root {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

option {
  color: white;
}

@media (max-width: 700px) {
  .hidden-sm {
    display: none;
  }
}
